<template>
  <v-content>
    <v-container grid-list-lg fluid style="margin-bottom: 58px;">
      <v-layout wrap align-start justify-start fill-height>
        <v-flex xs12 lg12 xl12>
          <app-title title="Suscripciones"> </app-title>
        </v-flex>
        <v-flex xs12 lg12 xl12>
          <v-card class="elevation-2">
            <v-data-table
              :headers="headers"
              :items="items"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPage"
              :loading="loading"
              class="elevation-0"
            >
              <template slot="items" slot-scope="props">
                <td>{{ props.item.name }}</td>
                <td>{{ typeShipping(props.item.type) }}</td>
                <td>
                  <v-chip v-if="props.item.status" color="success" text-color="white" label>
                    <v-icon left>check</v-icon> Activo
                  </v-chip>
                  <v-chip v-else color="error" text-color="white" label> <v-icon left>clear</v-icon> Inactivo </v-chip>
                </td>
                <td>
                  <v-btn v-if="user.role.id !== 7" class="mx-0" color="info" small @click="openAtributo(props.item)">
                    Editar
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialogEdit" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline"> Suscripciones </span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="name" label="Nombre" outline clearable></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-textarea v-model="description" multi-line outline clearable :label="`Descripcion`"> </v-textarea>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="orders" label="Numero de pedidos" outline clearable></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-select
                v-model="type"
                :items="[
                  {
                    id: 1,
                    name: 'Semanal'
                  },
                  {
                    id: 2,
                    name: 'Quincenal'
                  },
                  {
                    id: 3,
                    name: 'Mensual'
                  }
                ]"
                item-text="name"
                item-value="id"
                outline
                label="Tipo de entrega"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-radio-group v-model="status" class="pa-0 ma-0" :mandatory="false">
                <v-radio color="primary" label="Inactivo" :value="0"></v-radio>
                <v-radio color="primary" label="Activo" :value="1"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" flat @click.native="dialogEdit = false">Volver</v-btn>
          <v-btn color="primary" flat @click.native="sendDialog">Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn v-if="user.role.id !== 7" fixed dark fab bottom right color="accent" @click="openAtributo">
      <v-icon> add </v-icon>
    </v-btn>
  </v-content>
</template>

<script>
import appTitle from '../useful/title.vue'
import { GET_SUSCRIPTIONS } from '../../config'

export default {
  name: 'Suscription',
  components: { appTitle },
  data: () => ({
    loading: false,
    items: [],
    categories: [],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
    productsFilter: {
      on: true,
      off: true,
      hidden: false
    },
    searchText: '',
    headers: [
      {
        text: 'Nombre',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Tipo de entrega',
        align: 'left',
        sortable: true,
        value: 'type'
      },
      {
        text: 'Estado',
        align: 'left',
        sortable: true,
        value: 'active'
      },
      {
        text: '',
        align: 'left',
        sortable: false
      }
    ],
    status: false,
    name: '',
    idEdit: null,
    dialogEdit: false,
    type: null,
    description: null,
    orders: null
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      const res = await this.$http({
        method: 'GET',
        url: GET_SUSCRIPTIONS
      })
      this.items = res.data
    },
    async sendDialog() {
      try {
        const params = {
          name: this.name,
          status: this.status,
          description: this.description,
          orders: this.orders,
          type: this.type
        }
        if (this.idEdit) {
          await this.$http.put(`${GET_SUSCRIPTIONS}/${this.idEdit}`, params)
        } else {
          await this.$http.post(`${GET_SUSCRIPTIONS}`, params)
        }
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado'
        })
        this.dialogEdit = false
        this.getInfo()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    openAtributo(i) {
      if (i) {
        this.idEdit = i.id
        this.name = i.name
        this.status = i.status
        this.description = i.description
        this.orders = i.orders
        this.type = i.type
      }
      this.dialogEdit = true
    },
    openValues(id) {
      this.$router.push({ name: 'productsFeaturesValues', params: { id } })
    },
    typeShipping(type) {
      if (type === 1) {
        return 'Semanal'
      }
      if (type === 2) {
        return 'Quincenal'
      }
      if (type === 3) {
        return 'Mensual'
      }
    }
  }
}
</script>
